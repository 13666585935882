// import * as logo from './logo.svg';
import './App.css';
import Header from './components/Header.js';
import Footer from './components/Footer.js';

function App() {
  return (
    <div className="App">
      <header>
        <Header />
      </header>
      <div>
        <body>
          <div className="hero min-h-screen" style={{backgroundImage: 'url(/mtech-bg.png'}}>
            <div className="hero-overlay bg-opacity-10 blur"></div>
            <div className="hero-content text-left align-left text-neutral-content text-blue">
              <div className="max-w-md">
                <h1 className="mb-5 text-6xl font-bold">Homelab</h1>
                <p className="mb-5 text-2xl">Welcome to my homelab, where I create, experiment, and tinker with technology. It is a playground for my ideas and a space for me to learn. Join me on my journey of discovery and exploration.</p>
                <button className="btn btn-primary">Learn More</button>
              </div>
            </div>
          </div>
          <div className="hero min-h-screen bg-base-200">
            <div className="hero-content flex-col lg:flex-row">
              <h1 className="text-5xl font-bold">Purpose of the Homelab</h1>
              <p className="py-6">My homelab is an environment where I can test out new software and hardware, build applications, and transform ideas into reality. It’s where I can experiment without fear of failure. With this space, I can challenge myself to learn more, try new things, and expand my skills.</p>
            </div>
          </div>
        </body>
      </div>
      <footer>
        <Footer />
        {/* Call footer as imported from src/components/Footer.js */}
      </footer>
    </div>
  );
}

export default App;