// Header.js 
// MTECH Gen7 Site
// Kain M. Snyder

import React, {useState} from 'react';
import './styles.css';

function Header() {
    // State to control the visibility of the dropdown
    const [isDropdownVisible, setDropdownVisible] = useState(false);

    // Function to toggle the dropdown visibility
    const toggleDropdown = () => {
        setDropdownVisible(!isDropdownVisible);
    };

    return (
        <div>
            <div className="alert alert-warning p-2 pb-2" style={{ backgroundColor: '#f9e2af' }}>
                <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" /></svg>
                <span>Warning: This site is the development site. To return to the main site, press the info button.</span>
                <div>
                    <button className="btn btn-sm" href="https://malachi.technology">Info</button>
                </div>
            </div>
            <header className="bg-base-300 shadow-md">
                <div className="container mx-auto flex items-center justify-between">
		            <div className="flex items-center"> {/* Use flex to align logo and title horizontally */}
                        <img src="/MMNET_Gen_5X_Logo_NoBG.png" alt="Logo" className="mr-2" style={{ maxWidth: '9%', maxHeight: '9%' }} />
                        <div className="text-2xl text-primary blue font-bold">
                            <a href="https://malachi.technology">Malachi Technologies</a>
                        </div>
                    </div>
                    <div className="navbar-end justify-end">
                        <nav className="flex items-right join justify-end"> {/* Add space-x for spacing between navigation elements */}
                            <a className="btn btn-ghost btn-sm join-item" href="https://malachi.technology/Blog">Blog</a>
                            <details className="relative" style={{ zIndex: 1 }}>
                                <summary className="btn btn-ghost btn-sm join-item" onClick={toggleDropdown}>Help Desk</summary>
                                {isDropdownVisible && (
                                    <ul className={`menu menu-dropdown lg:menu-horizontal absolute right-0 mt-2 rounded-box bg-base-100 ${isDropdownVisible ? 'block' : 'hidden'}`} style={{ zIndex: 2, minWidth: '150px' }}>
                                    <li><a href="https://help.malachi.technology/index.php">Help Portal</a></li>
                                        <li><a href="https://help.malachi.technology/index.php?a=add">Submit a Ticket</a></li>
                                    </ul>
                                )}
                            </details>
                            <a className="btn btn-ghost btn-sm join-item" href="https://uptime.malachi.technology">Service Status</a>
                            <a className="btn btn-ghost btn-sm join-item" href="https://malachi.technology/About">About</a>
                        </nav>
                    </div>
                </div>
            </header>
        </div>
    );
}

export default Header;
