import React from 'react';
import './styles.css';

function Footer() {
    return (
        <footer class="footer footer-center p-10 bg-base-300 text-base-content rounded">
            <nav class="grid grid-flow-col gap-4">
                <a class="link link-hover" href="https://malachi.technology/About">About us</a> 
                <a class="link link-hover" href="https://malachi.technology/Blog">Blog</a>
                <a class="link link-hover" href="mailto:help@malachi.technology">Contact</a> 
                <a class="link link-hover" href="https://help.malachi.technology/index.php">Help Desk</a> 
                <a class="link link-hover" href="https://uptime.malachi.technology">Services Status</a>
            </nav> 
            <aside>
                <p>Copyright © 2023 - All right reserved by Malachi Technologies</p>
            </aside>
        </footer>
    );
}

export default Footer;